import Button from "@/components/Buttons/Button";
import Container from "@/components/Container/Container";
import Layout from "@/components/Layout/Layout";
import { Heading, H5Css, H4Css } from "@/components/Typography";
import { mq } from "@/constants/breakpoints";
import { css } from "@emotion/react";
import Link from "next/link";
import Balancer from "react-wrap-balancer";
import mobile404Image from "../../public/images/404/404.svg";
import large404Image from "../../public/images/404/404_l.svg";
import Image from "next/image";

const Page = () => {
  return (
    <Layout
      skipScripts
      heroContainerCss={css`
        background: url(/images/404/BG.svg),
          linear-gradient(112.24deg, #048cdd 4.51%, #32b2ff 100.81%);
        background-size: cover;
        background-repeat: no-repeat;
      `}
      hero={
        <Container>
          <div
            css={css`
              margin-top: 110px;
              ${mq["lg"]} {
                margin-top: 160px;
              }
            `}
          >
            <Image
              src={mobile404Image}
              alt="Page Not Found"
              priority
              css={css`
                display: block;
                margin: auto;
                ${mq["lg"]} {
                  display: none;
                }
              `}
            />
            <Image
              src={large404Image}
              alt="Page Not Found"
              priority
              css={css`
                display: none;
                margin: auto;
                ${mq["lg"]} {
                  display: block;
                }
              `}
            />
          </div>
          <Heading
            level={1}
            css={css`
              color: #fff;
              text-align: center;
              margin-top: 24px;
              ${H5Css}
              ${mq["lg"]} {
                ${H4Css}
                margin-top: 44px;
              }
            `}
          >
            <Balancer>Oops! This page is not available.</Balancer>
          </Heading>
          <div
            css={css`
              padding-bottom: 160px;
            `}
          >
            <Link href="/">
              <Button
                jwType="light"
                css={css`
                  margin: 24px auto 0;
                  ${mq["lg"]} {
                    margin-top: 50px;
                  }
                `}
              >
                Go to Homepage
              </Button>
            </Link>
          </div>
        </Container>
      }
    >
      <div />
    </Layout>
  );
};

export const getStaticProps = async () => {
  return { props: {} };
};

export default Page;
